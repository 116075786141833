<template>
  <section>
    <div class="container has-text-left mt-6">
      <div class="tile is-ancestor">
        <div class="tile is-parent">
          <article class="tile is-child">
            <div class="content">
              <h2 class="title">Kontaktieren Sie uns</h2>
              <p class="subtitle">Wir antworten schnellstmöglich</p>
              <!-- <div class="content">
                <figure class="image is-4by3">
                  <img src="https://bulma.io/images/placeholders/640x480.png" />
                </figure>
              </div> -->
            </div>
          </article>
        </div>
        <div class="tile is-8">
          <div class="tile is-parent">
            <article class="tile is-child notification">
              <b-field label="Anrede">
                <b-select
                  placeholder="Wählen Sie eine Anrede"
                  expanded
                  v-model="form.salutation"
                  required
                >
                  <option
                    v-for="option in salutationOptions"
                    :key="option.id"
                    :value="option.value"
                  >
                    {{ option.text }}
                  </option>
                </b-select>
              </b-field>
              <b-field label="Vorname" expanded>
                <b-input
                  placeholder="Vorname"
                  type="text"
                  required
                  v-model="form.firstname"
                ></b-input>
              </b-field>
              <b-field label="Nachname" expanded>
                <b-input
                  placeholder="Nachname"
                  type="text"
                  required
                  v-model="form.lastname"
                ></b-input>
              </b-field>
              <b-field label="Email">
                <b-input
                  placeholder="Email"
                  type="email"
                  required
                  maxlength="300"
                  v-model="form.email"
                >
                </b-input>
              </b-field>
              <b-field label="Kategorie">
                <b-select
                  placeholder="Wählen Sie eine Kategorie"
                  expanded
                  required
                  v-model="form.messageCategory"
                >
                  <option
                    v-for="option in categoryOptions"
                    :key="option.id"
                    :value="option.value"
                  >
                    {{ option.text }}
                  </option>
                </b-select>
              </b-field>
              <b-field label="Nachricht">
                <b-input
                  placeholder="Fromulieren Sie Ihr Anliegen"
                  maxlength="200"
                  type="textarea"
                  required
                  v-model="form.message"
                ></b-input>
              </b-field>

              <button class="button is-primary" @click="submitForm">
                Senden
              </button>
            </article>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import axios from "axios";

export default {
  name: "Contact",
  data() {
    return {
      form: {
        salutation: null,
        firstname: null,
        lastname: null,
        email: null,
        phone: null,
        messageCategory: null,
        message: null,
      },
      salutationOptions: [
        { value: "Herr", text: "Herr" },
        { value: "Frau", text: "Frau" },
        { value: "Divers", text: "Divers" },
        { value: "Keine Angabe", text: "Keine Angabe" },
      ],
      categoryOptions: [
        { value: "general", text: "Allgemein" },
        { value: "price", text: "Preisanfrage" },
        { value: "website", text: "Websiteanfrage" },
        { value: "app", text: "Fragen zu unseren Apps" },
        { value: "other", text: "Sonstiges" },
      ],
    };
  },
  methods: {
    submitForm() {
      if (
        this.form.salutation &&
        this.form.firstname &&
        this.form.lastname &&
        this.form.messageCategory &&
        this.form.message
      ) {
        axios
          .post("/contact-form", this.form, {
            headers: {
              "Content-Type": "application/json",
            },
          })
          .then((response) => {
            if (response.status === 200) {
              this.form.salutation = null;
              this.form.firstname = null;
              this.form.lastname = null;
              this.form.email = null;
              this.form.messageCategory = null;
              this.form.message = null;
              this.$buefy.toast.open({
                duration: 4200,
                message: "Ihre Anfrage wurde erfolgreich versendet",
                position: "is-bottom",
                type: "is-success",
              });
            } else {
              this.$buefy.toast.open({
                duration: 4200,
                message: "Ihre Anfrage konnte nicht versendet werden",
                position: "is-bottom",
                type: "is-danger",
              });
            }
          })
          .catch(() =>
            this.$buefy.toast.open({
              duration: 4200,
              message: "Ihre Anfrage konnte nicht versendet werden",
              position: "is-bottom",
              type: "is-danger",
            })
          );
      } else {
        this.$buefy.toast.open({
          duration: 4200,
          message: "Bitte alle Felder ausfüllen",
          position: "is-bottom",
          type: "is-danger",
        });
      }
    },
  },
};
</script>
<style scoped>
</style>
